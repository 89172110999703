/* eslint-disable @next/next/no-img-element */
import AOS from "aos"
import "aos/dist/aos.css"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import Nav from "./styles"

const Navbar = () => {
  const [toggle, toggleNav] = useState(false)
  const router = useRouter()
  const [clicked, setClicked] = useState(false)
  const [drop, setDrop] = useState(false)
  useEffect(() => {
    AOS.init()
  }, [])

  useEffect(() => {
    const handleScroll = (event: any) => {
      setClicked(false)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const dd = [
    {
      icon: "https://res.cloudinary.com/gigx-technologies/image/upload/v1670626884/xpad/rem-white_gbloeb.svg",
      title: "Cross Border Payment",
      subtitle: "Send and receive cash directly overseas",
      route: "/remittance",
    },
    {
      icon: "https://res.cloudinary.com/gigx-technologies/image/upload/v1670626884/xpad/card-white_s3u0st.svg",
      title: "XPAD Virtual Cards",
      subtitle: "Manage all online transactions seamlessly",
    },
  ]

  const dm = [
    {
      icon: "https://res.cloudinary.com/gigx-technologies/image/upload/v1669725654/xpad/cbp_dark_o2ozf1.svg",
      title: "Cross Border Payment",
      subtitle: "Send and receive cash directly overseas",
      route: "/remittance",
    },
    {
      icon: "https://res.cloudinary.com/gigx-technologies/image/upload/v1669725654/xpad/xcard_dark_vg2xkk.svg",
      title: "XPAD Virtual Cards",
      subtitle: "Manage all online transactions seamlessly",
    },
  ]

  useEffect(() => {
    const handleScroll = (event: any) => {
      setClicked(false)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Nav
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-duration="1500"
    >
      {!toggle && (
        <div className="navbar">
          <img
            src="https://res.cloudinary.com/gigx-technologies/image/upload/v1668764383/xpad/xpad_logo_t5kszj.svg"
            alt=""
            width={50}
            onClick={() => router.push("/")}
            style={{ cursor: "pointer" }}
            className="nav__logo"
          />
          <div className="menu">
            <div className="menu__item">
              <p
                className={
                  router?.pathname === "/remittance"
                    ? "menu__item__active"
                    : "menu__item__text"
                }
                onClick={() => setClicked(!clicked)}
                // style={{router.path ===}}
                // onMouseOver={() => setClicked(true)}
                // onMouseOut={() => setClicked(false)}
              >
                Products
              </p>
              {clicked && (
                <div className="menu__item__dd">
                  {dd.map((d, index) => (
                    <div
                      className="inner"
                      key={index}
                      onClick={() => {
                        router.push(`${d.route}`)
                        setClicked(false)
                      }}
                    >
                      <img src={d.icon} alt="" />
                      <div className="inner__text">
                        <p>{d.title}</p>
                        <p>{d.subtitle}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="menu__item" onClick={() => router.push("/")}>
              <p className="menu__item__text">About</p>
            </div>
            <div className="menu__item" onClick={() => router.push("/support")}>
              <p
                className={
                  router?.pathname === "/support"
                    ? "menu__item__active"
                    : "menu__item__text"
                }
              >
                Support
              </p>
            </div>
          </div>

          <div
            className={!toggle ? "nav_icon" : "unnav_icon"}
            onClick={() => toggleNav(!toggle)}
          >
            <img
              src={
                !toggle
                  ? "https://res.cloudinary.com/gigx-technologies/image/upload/v1669715665/xpad/Group_24487_u8wynm.svg"
                  : "https://res.cloudinary.com/gigx-technologies/image/upload/v1669721386/xpad/close_snduha.png"
              }
              alt=""
            />
          </div>
        </div>
      )}

      {toggle && (
        <div className="overlay">
          <div className="overlay__header">
            <div className="overlay__header__logo">
              <img
                src="https://res.cloudinary.com/gigx-technologies/image/upload/v1669721645/xpad/xpad-light_m4epb1.svg"
                alt=""
              />
            </div>

            <div
              className="overlay__header__close"
              onClick={() => toggleNav(!toggle)}
            >
              <img
                src="https://res.cloudinary.com/gigx-technologies/image/upload/v1669721386/xpad/close_snduha.png"
                alt=""
              />
            </div>
          </div>
          <div className="overlay__menu">
            <div
              className="menu__item"
              onClick={() => {
                setDrop(!drop)
              }}
            >
              <p className="menu__itemList">Products</p>
            </div>
            {drop && (
              <div className="menu__itemed">
                {dm.map((d, index) => (
                  <div
                    className="inners"
                    key={index}
                    onClick={() => {
                      router.push(`${d.route}`)
                      toggleNav(false)
                      setDrop(false)
                    }}
                  >
                    <img src={d.icon} alt="" />
                    <div
                      className="menu__item__sub"
                      // className="inner__text"
                    >
                      <p>{d.title}</p>
                      <p>{d.subtitle}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="menu__item" onClick={() => toggleNav(!toggle)}>
              <Link href="/remittance">
                <p className="menu__itemList">About</p>
              </Link>
            </div>
            <div className="menu__item" onClick={() => toggleNav(!toggle)}>
              <Link href="/support">
                <p className="menu__itemList">Support</p>
              </Link>
            </div>
          </div>
        </div>
      )}
    </Nav>
  )
}

export default Navbar
